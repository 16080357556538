<template>
	<div class="Contato_clientes">
		<v-layout row wrap class="mx-5 my-5">
			<v-flex xs6>
				<h2 class="indigo--text text--darken-4">Contato a Clientes</h2>
			</v-flex>
			<v-flex xs6 class="text-end">
			</v-flex>
		</v-layout>
		<v-layout row wrap class="mx-5 my-5">
			<v-flex xs12>
				<v-layout row wrap class="my-3 mx-0 pa-3 grey lighten-4 rounded-lg">
					<v-flex xs12 class="pa-3">
						<v-row>
							<v-flex xs12 class="pr-3">
								<h5>Filtros Pessoa Física</h5>
							</v-flex>
							<v-flex xs3 sm4 lg3 class="pr-3">
								<v-text-field label="CPF" v-model="filters.cpf" outlined dense counter clearable maxlength="11"></v-text-field>
							</v-flex>
							<v-flex xs3 sm4 lg3 class="pr-3">
								<v-text-field label="Nome" v-model="filters.nome" outlined dense clearable></v-text-field>
							</v-flex>
						</v-row>
					</v-flex>
					<v-flex xs12 class="pa-3">
						<v-row>
							<v-flex xs12 class="pr-3">
								<h5>Filtros Pessoa Jurídica</h5>
							</v-flex>
							<v-flex xs3 sm4 lg3 class="pr-3">
								<v-text-field label="CNPJ" v-model="filters.cnpj" outlined dense counter clearable maxlength="14"></v-text-field>
							</v-flex>
							<v-flex xs3 sm4 lg3 class="pr-3">
								<v-text-field label="Razão Social" v-model="filters.razao_social" outlined dense clearable></v-text-field>
							</v-flex>
							<v-flex xs3 sm4 lg3 class="pr-3">
								<v-text-field label="Nome Fantasia" v-model="filters.nome_fantasia" outlined dense clearable></v-text-field>
							</v-flex>
						</v-row>
					</v-flex>
					<v-flex xs12 class="pa-3">
						<v-row>
							<v-flex xs12 class="pr-3">
								<h5>Filtros Pessoa Física e Jurídica</h5>
							</v-flex>
							<v-flex xs3 sm4 lg3 class="pr-3">
								<v-text-field label="Telefone" v-model="filters.telefone" outlined dense clearable></v-text-field>
							</v-flex>
						</v-row>
					</v-flex>

					<v-flex xs12 class="pa-3">
						<v-row>
							<v-flex xs12 class="pr-3">
								<h5>Filtros Gerais</h5>
							</v-flex>
							<v-flex xs3 sm4 lg3 class="pr-3">
								<v-autocomplete label="Ano" v-model="filters.ano" :items="data.listaAnosComRegistroDeMeta" item-text="ano" item-value="ano" outlined dense></v-autocomplete>
							</v-flex>
							<v-flex xs3 sm4 lg3 class="pr-3" v-if="[1, 2].indexOf(parseInt(session.id_cargo_usuario)) >= 0">
								<v-autocomplete label="Vendedor" v-model="filters.nome_vendedor" :items="data.listagemVendedores" item-text="nome" item-value="nome" outlined dense clearable></v-autocomplete>
							</v-flex>
							<v-flex xs3 sm4 lg3 class="pr-3">
								<v-autocomplete label="Executado" v-model="filters.executado" :items="data.listagemStatusMetas" item-text="desc_status" item-value="desc_status" outlined dense clearable></v-autocomplete>
							</v-flex>
						</v-row>
					</v-flex>

					<v-flex xs12 class="text-end">
						<v-btn depressed dark color="indigo darken-4" @click="limparFiltro">
							<v-icon left>clear_all</v-icon>
							<span>Limpar filtro</span>
						</v-btn>
					</v-flex>
				</v-layout>
			</v-flex>

			<v-flex xs12>
				<v-card>
					<v-tabs v-model="activeTab" background-color="grey lighten-4">
						<v-tabs-slider color="indigo darken-4"></v-tabs-slider>
						<v-tab v-for="item in tablist" :key="item" class="indigo--text text--darken-4">{{ item }}</v-tab>
					</v-tabs>

					<v-tabs-items v-model="activeTab">
						<v-tab-item key="Pessoa Jurídica">
							<v-card flat>
								<v-card-text>
									<v-data-table
										:headers="listagemHeadersPessoaJuridica"
										:items="data.listagemPessoaJuridica"
										:footer-props="{'items-per-page-options': [5, 10, 20, 50, 100]}"
										class="grey lighten-4 grey--text text--darken-2"
										style="white-space:nowrap;"
									>
										<template v-slot:item.actions="{ item }">
											<v-menu offset-y>
												<template v-slot:activator="{ on, attrs }">
													<v-btn depressed dark color="indigo darken-4" class="rounded-circle" style="width: 36px !important; min-width: 36px !important; height: 36px;" v-bind="attrs" v-on="on">
														<v-icon>menu</v-icon>
													</v-btn>
												</template>
												<v-list>
													<v-list-item>
														<router-link to="#">
															<v-list-item-title class="grey--text text--darken-2" @click="executarContato(item)">
																<v-icon color="indigo darken-4" class="mr-2">mdi-phone-check</v-icon> Registrar contato
															</v-list-item-title>
														</router-link>
													</v-list-item>
												</v-list>
											</v-menu>
										</template>
										<template v-slot:item.executado="{ item }">
											<v-chip class="ma-2 white--text text--white" :color="item.executado == 'Sim' ? 'success' : 'orange darken-1'">{{item.executado}}</v-chip>
										</template>
										<template v-slot:item.retornar="{ item }">
											<v-chip class="ma-2 white--text text--white" color="success" v-if="item.retornar == 'Sim'">{{item.executado}}</v-chip>
											<v-chip class="ma-2 white--text text--white" color="blue-grey lighten-3" v-if="item.retornar == 'Não'">{{item.executado}}</v-chip>
										</template>
										<template v-slot:item.dt_criacao="{ item }">
											{{ (item.dt_criacao) ? new Date(item.dt_criacao).toLocaleDateString("pt-BR") : '' }}
										</template>
										<template v-slot:item.dt_execucao="{ item }">
											{{ (item.dt_execucao) ? new Date(item.dt_execucao).toLocaleDateString("pt-BR") : '' }}
										</template>
										<template v-slot:item.dt_efetivacao_execucao="{ item }">
											{{ (item.dt_efetivacao_execucao) ? new Date(item.dt_efetivacao_execucao).toLocaleDateString("pt-BR") : '' }}
										</template>
									</v-data-table>
									<v-flex xs12 class="text-end">
										<v-menu offset-y>
											<template v-slot:activator="{ on, attrs }">
												<v-btn depressed dark color="indigo darken-4" class="my-5" :loading="loadingStatusExport" v-bind="attrs" v-on="on">
													<v-icon>file_download</v-icon>
													<span>Exportar planilha</span>
												</v-btn>
											</template>
											<v-list>
												<v-list-item>
													<router-link to="#">
														<v-list-item-title class="grey--text text--darken-2" @click="exportarListagemGeral(data.listagemPessoaJuridica, 'PJ')">
															<v-icon color="indigo darken-4" class="mr-2">mdi-playlist-check</v-icon> Completa
														</v-list-item-title>
													</router-link>
												</v-list-item>
												<v-list-item>
													<router-link to="#">
														<v-list-item-title class="grey--text text--darken-2" @click="exportarListagemFiltros('PJ')">
															<v-icon color="indigo darken-4" class="mr-2">mdi-filter-outline</v-icon> Com filtros
														</v-list-item-title>
													</router-link>
												</v-list-item>
											</v-list>
										</v-menu>
									</v-flex>
								</v-card-text>
							</v-card>
						</v-tab-item>
						<v-tab-item key="Pessoa Física">
							<v-card flat>
								<v-card-text>
									<v-data-table
										:headers="listagemHeadersPessoaFisica"
										:items="data.listagemPessoaFisica"
										:footer-props="{'items-per-page-options': [5, 10, 20, 50, 100]}"
										class="grey lighten-4 grey--text text--darken-2"
										style="white-space:nowrap;"
									>
										<template v-slot:item.actions="{ item }">
											<v-menu offset-y>
												<template v-slot:activator="{ on, attrs }">
													<v-btn depressed dark color="indigo darken-4" class="rounded-circle" style="width: 36px !important; min-width: 36px !important; height: 36px;" v-bind="attrs" v-on="on">
														<v-icon>menu</v-icon>
													</v-btn>
												</template>
												<v-list>
													<v-list-item>
														<router-link to="#">
															<v-list-item-title class="grey--text text--darken-2" @click="executarContato(item)">
																<v-icon color="indigo darken-4" class="mr-2">mdi-phone-check</v-icon> Registrar contato
															</v-list-item-title>
														</router-link>
													</v-list-item>
												</v-list>
											</v-menu>
										</template>
										<template v-slot:item.executado="{ item }">
											<v-chip class="ma-2 white--text text--white" :color="item.executado == 'Sim' ? 'success' : 'orange darken-1'">{{item.executado}}</v-chip>
										</template>
										<template v-slot:item.retornar="{ item }">
											<v-chip class="ma-2 white--text text--white" color="success" v-if="item.retornar == 'Sim'">{{item.executado}}</v-chip>
											<v-chip class="ma-2 white--text text--white" color="blue-grey lighten-3" v-if="item.retornar == 'Não'">{{item.executado}}</v-chip>
										</template>
										<template v-slot:item.dt_criacao="{ item }">
											{{ (item.dt_criacao) ? new Date(item.dt_criacao).toLocaleDateString("pt-BR") : '' }}
										</template>
										<template v-slot:item.dt_execucao="{ item }">
											{{ (item.dt_execucao) ? new Date(item.dt_execucao).toLocaleDateString("pt-BR") : '' }}
										</template>
										<template v-slot:item.dt_efetivacao_execucao="{ item }">
											{{ (item.dt_efetivacao_execucao) ? new Date(item.dt_efetivacao_execucao).toLocaleDateString("pt-BR") : '' }}
										</template>
									</v-data-table>
									<v-flex xs12 class="text-end">
										<v-menu offset-y>
											<template v-slot:activator="{ on, attrs }">
												<v-btn depressed dark color="indigo darken-4" class="my-5" :loading="loadingStatusExport" v-bind="attrs" v-on="on">
													<v-icon>file_download</v-icon>
													<span>Exportar planilha</span>
												</v-btn>
											</template>
											<v-list>
												<v-list-item>
													<router-link to="#">
														<v-list-item-title class="grey--text text--darken-2" @click="exportarListagemGeral(data.listagemPessoaFisica, 'PF')">
															<v-icon color="indigo darken-4" class="mr-2">mdi-playlist-check</v-icon> Completa
														</v-list-item-title>
													</router-link>
												</v-list-item>
												<v-list-item>
													<router-link to="#">
														<v-list-item-title class="grey--text text--darken-2" @click="exportarListagemFiltros('PF')">
															<v-icon color="indigo darken-4" class="mr-2">mdi-filter-outline</v-icon> Com filtros
														</v-list-item-title>
													</router-link>
												</v-list-item>
											</v-list>
										</v-menu>
									</v-flex>
								</v-card-text>
							</v-card>
						</v-tab-item>
					</v-tabs-items>
				</v-card>
			</v-flex>
		</v-layout>

		<v-dialog v-model="showDialogExecutar" max-width="600px">
			<v-card>
				<v-system-bar color="blue darken-4"></v-system-bar>

				<v-card-title class="headline grey--text text--darken-2">Registrar Contato</v-card-title>

				<v-card-text>
					<v-form class="my-3" ref="formExecucao">
						<v-row>
							<v-col cols="12">
								<v-autocomplete prepend-icon="mdi-phone-check" label="Retornar?" v-model="dadosExecucao.retornar" :items="data.listagemStatusMetas" item-text="desc_status" item-value="desc_status" :rules="rules.selectValidate" dense clearable></v-autocomplete>
							</v-col>
							<v-col cols="12">
								<v-textarea prepend-icon="mdi-comment-outline" label="Feedback" v-model="dadosExecucao.feedback" :rules="rules.inputTextValidate"></v-textarea>
							</v-col>
						</v-row>
					</v-form>
				</v-card-text>

				<v-card-actions class="my-5">
					<v-btn depressed dark color="blue darken-4" @click="showDialogExecutar = !showDialogExecutar">
						<v-icon left>close</v-icon>
						<span>Cancelar</span>
					</v-btn>
					<v-btn depressed dark color="indigo darken-4" @click="submitExecucao()" :loading="loadingStatus">
						<v-icon left>mdi-floppy</v-icon>
						<span>Registrar</span>
					</v-btn>
				</v-card-actions>

				<v-system-bar color="blue-grey darken-1"></v-system-bar>
			</v-card>
		</v-dialog>

		<v-snackbar v-model="snackbarShower" :timeout="3000" top :color="snackbarColor">
			{{snackbarText}}
			<template v-slot:action="{ attrs }">
				<v-btn v-bind="attrs" dark fab depressed small :color="snackbarColor" @click="snackbarShower = false">
					<v-icon small>close</v-icon>
				</v-btn>
			</template>
		</v-snackbar>
	</div>
</template>
<script>
import Excel from 'exceljs';
export default {
	data() {
		return {
			session: {
				id_cargo_usuario: Number(localStorage.getItem('id_cargo_usuario')),
				id_usuario: Number(localStorage.getItem('id_usuario'))
			},
			tablist: ['Pessoa Jurídica', 'Pessoa Física'],
			activeTab: null,
			rules: {
				inputTextValidate: [
					v => v != '' || 'Campo obrigatório',
					v => v.length > 0 || 'Campo obrigatório'
				],
				selectValidate: [
					v => v != '' || 'Campo obrigatório',
					v => v != null || 'Campo obrigatório'
				]
			},
			data: {
				listagemPessoaFisica: [{cpf: 'Buscando...'}],
				listagemPessoaJuridica: [{cnpj: 'Buscando...'}],
				listagemClientesPessoaFisica: [{desc_cliente: 'Buscando...'}],
				listagemClientesPessoaJuridica: [{desc_cliente: 'Buscando...'}],
				listagemVendedores: [{nome: 'Buscando...'}],
				listagemStatusMetas: [{desc_status: 'Buscando...'}],
				listaAnosComRegistroDeMeta: [{ano: 'Buscando...'}]
			},
			filters: {
				cpf: '',
				nome: '',
				cnpj: '',
				razao_social: '',
				nome_fantasia: '',
				telefone: '',
				nome_vendedor: '',
				executado: '',
				ano: new Date().getFullYear().toString()//por padrão traz o ano atual
			},
			snackbarColor: 'green',
			snackbarText: '',
			snackbarShower: false,

			loadingStatus: false,
			loadingStatusExport: false,

			dadosExecucao: {
				id_meta: 0,
				retornar: '',
				feedback: ''
			},
			showDialogExecutar: false
		}
	},
	watch: {
		'filters.ano': {
			handler (newValue, oldValue) {//quando muda o ano, busca a listagem do ano selecionado
				if (newValue != oldValue) {
					this.buscaListaComFiltros();
				}
			},
			deep: true
		}
	},
	methods: {
		exportarListagemFiltros(natureza) {
			this.loadingStatus = true;
			var thisEl = this;

			var dataToSend = this.filters;
			dataToSend.token = localStorage.getItem('token');
			dataToSend.natureza = natureza;
			dataToSend.id_cargo_usuario = this.session.id_cargo_usuario;
			dataToSend.id_usuario = this.session.id_usuario;
			
			thisEl.$http.post(//requisição ajax
                'contato_clientes/exportar_com_filtros',
                dataToSend,
                { emulateJSON: true }
            ).then(
                function(r){//sucesso
                	if (r.body.token == false) {
                    	thisEl.snackbarColor = 'red';
	      				thisEl.snackbarText = r.body.message;
	      				thisEl.snackbarShower = true;
	      				
	      				setTimeout(function(){
                    		thisEl.$router.push('/logout');
                    	}, 2000);
                    } else {
                        if (r.body.success) {
                        	if (r.body.listagemGeral.length) {
	                        	thisEl.snackbarColor = 'green';
			      				thisEl.snackbarText = 'Listagem gerada!';
	                        	thisEl.snackbarShower = true;

	                        	let dados = {};

								dados.headers = Object.keys(r.body.listagemGeral[0]).map((item) => {
									return {name: item};
								});

								dados.values = r.body.listagemGeral;
								
								this.exportXlsAjax(dados, 'metas_' + natureza, ['id_meta', 'id_vendedor', 'id_tipo_cliente', 'id_cliente']);
							} else {
								thisEl.snackbarColor = 'orange darken-1';
			      				thisEl.snackbarText = 'Sem dados para exportar!';
	                        	thisEl.snackbarShower = true;
							}
                        } else {
                        	thisEl.loadingStatus = false;
                        	thisEl.snackbarColor = 'red';
		      				thisEl.snackbarText = r.body.message;
		      				thisEl.snackbarShower = true;
                        }
                    }
				},
                function(error){//erro na requisição, por exemplo 404
                    thisEl.loadingStatus = false;
                	thisEl.snackbarColor = 'red';
      				thisEl.snackbarText = 'Houve um erro com a requisição.';
      				thisEl.snackbarShower = true;
                }
            ).finally(function(){//executa este método após toda execução do then
                //do something
            });
		},
		exportarListagemGeral(dadosListagem, natureza) {
			let dados = {};

			dados.headers = Object.keys(dadosListagem[0]).map((item) => {
				return {name: item};
			});

			dados.values = dadosListagem;
			
			this.exportXlsAjax(dados, 'metas_' + natureza, ['id_meta', 'id_vendedor', 'id_tipo_cliente', 'id_cliente']);
		},
		exportXlsAjax(dados, filename, numericHeaders){

			console.log(dados);

			var thisEl = this;
            const workbook = new Excel.Workbook()//novo arquivo
            const worksheet = workbook.addWorksheet('Sheet1')//nova sheet

            var headers = [];
	        for (var i = 0; i < dados.headers.length; i++) { 
	        	headers.push(dados.headers[i].name);
	        }

	        var lines = [];
			for (var i = 0; i < dados.values.length; i++) { 
				var line = [];
				for (var ii = 0; ii < headers.length; ii++) {
					let valueToPush = eval('dados.values[i].' + headers[ii]);
					valueToPush = ((valueToPush == null) ? '' : valueToPush);

					if (numericHeaders.indexOf(headers[ii]) >= 0) {
						valueToPush = Number(valueToPush);
					}
					
					line.push(valueToPush);
				}

				lines.push(line);
			}

            //adiciona linhas na sheet
            worksheet.addRows([headers])
            worksheet.addRows(lines)

            workbook.xlsx.writeBuffer().then(function(buffer){//cria buffer do arquivo
                const data = new Blob([buffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});//cria arquivo em Blob
                
                var link = document.createElement('a');

                link.setAttribute('href', URL.createObjectURL(data));
                link.setAttribute('download', filename + '.xlsx');
                
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                
                link.click();
                document.body.removeChild(link);
            });
        },
        executarContato(dados) {
			this.dadosExecucao.id_meta = dados.id_meta;
			this.showDialogExecutar = true;
		},
		submitExecucao () {
			if (this.$refs.formExecucao.validate()) {
				this.loadingStatus = true;

				var thisEl = this;

				var dataToSend = this.dadosExecucao;
				dataToSend.token = localStorage.getItem('token');

				console.log(dataToSend);

				thisEl.$http.post(//requisição ajax
	                'contato_clientes/executar_meta',
	                dataToSend,
	                { emulateJSON: true }
	            ).then(
	                function(r){//sucesso
	                	if (r.body.token == false) {
	                    	thisEl.snackbarColor = 'red';
		      				thisEl.snackbarText = r.body.message;
		      				thisEl.snackbarShower = true;
		      				
		      				setTimeout(function(){
	                    		thisEl.$router.push('/logout');
	                    	}, 2000);
	                    } else {
	                        if (r.body.success) {
	                        	thisEl.snackbarColor = 'green';
			      				thisEl.snackbarText = r.body.message;
	                        	thisEl.snackbarShower = true;

	                        	setTimeout(function(){
	                        		thisEl.loadingStatus = false;
	                        		thisEl.showDialogExecutar = false;

	                        		thisEl.dadosEfetivacao = {
	                        			id_meta: 0,
	                        			retornar: '',
										feedback: ''
									};
	                        	}, 1000);

	                        	this.buscaListaDefault();
	                        } else {
	                        	thisEl.loadingStatus = false;
	                        	thisEl.snackbarColor = 'red';
			      				thisEl.snackbarText = r.body.message;
			      				thisEl.snackbarShower = true;
	                        }
	                    }
					},
	                function(error){//erro na requisição, por exemplo 404
	                    thisEl.loadingStatus = false;
	                	thisEl.snackbarColor = 'red';
	      				thisEl.snackbarText = 'Houve um erro com a requisição.';
	      				thisEl.snackbarShower = true;
	                }
	            ).finally(function(){//executa este método após toda execução do then
	                //do something
	            });
			}
		},
		limparFiltro() {
			var thisEl = this;
			Object.keys(thisEl.filters).forEach(function(key) {
				if (key == 'ano') {
					thisEl.filters[key] = new Date().getFullYear().toString();//por padrão traz o ano atual
				} else {
					thisEl.filters[key] = '';
				}
				
			});
		},
		buscaListaComFiltros() {
			this.data.listagemPessoaFisica = [{cpf: 'Buscando...'}];
			this.data.listagemPessoaJuridica = [{cnpj: 'Buscando...'}];

			this.buscaListaDefault({ano: this.filters.ano});
		},
		buscaListaDefault(post = {}) {
			var thisEl = this;

			var dataToSend = post;
			dataToSend.token = localStorage.getItem('token');
			dataToSend.id_cargo_usuario = this.session.id_cargo_usuario;
			dataToSend.id_usuario = this.session.id_usuario;

			thisEl.$http.post(//requisição ajax
	            'contato_clientes/listagem_metas',
	            dataToSend,
	            { emulateJSON: true }
	        ).then(
	            function(r){//sucesso
	                if (r.body.token == false) {
	                	thisEl.snackbarColor = 'red';
	      				thisEl.snackbarText = r.body.message;
	      				thisEl.snackbarShower = true;
	      				
	      				setTimeout(function(){
	                		thisEl.$router.push('/logout');
	                	}, 2000);
	                } else {
	                	thisEl.data.listagemPessoaFisica = r.body.listagemPessoaFisica;
	                	thisEl.data.listagemPessoaJuridica = r.body.listagemPessoaJuridica;
	                }
				},
	            function(error){//erro na requisição, por exemplo 404
	                thisEl.loadingStatus = false;
	            	thisEl.snackbarColor = 'red';
	  				thisEl.snackbarText = 'Houve um erro com a requisição.';
	  				thisEl.snackbarShower = true;
	            }
	        );
		},
		buscaFiltros() {
			var thisEl = this;
			thisEl.$http.post(//requisição ajax
	            'contato_clientes/listagem_filtros',
	            { token: localStorage.getItem('token') },
	            { emulateJSON: true }
	        ).then(
	            function(r){//sucesso
	                if (r.body.token == false) {
	                	thisEl.snackbarColor = 'red';
	      				thisEl.snackbarText = r.body.message;
	      				thisEl.snackbarShower = true;
	      				
	      				setTimeout(function(){
	                		thisEl.$router.push('/logout');
	                	}, 2000);
	                } else {
	                	thisEl.data.listagemTiposClientes = r.body.listagemTiposClientes;
	                	thisEl.data.listagemClientesPessoaFisica = r.body.listagemClientesPessoaFisica;
						thisEl.data.listagemClientesPessoaJuridica = r.body.listagemClientesPessoaJuridica;
						thisEl.data.listagemVendedores = r.body.listagemVendedores;
						thisEl.data.listagemStatusMetas = r.body.listagemMetasStatus;
						thisEl.data.listaAnosComRegistroDeMeta = r.body.listagemAnosComRegistroDeMeta;
	                }
				},
	            function(error){//erro na requisição, por exemplo 404
	                thisEl.loadingStatus = false;
	            	thisEl.snackbarColor = 'red';
	  				thisEl.snackbarText = 'Houve um erro com a requisição.';
	  				thisEl.snackbarShower = true;
	            }
	        );
		}
	},
	computed: {
		listagemHeadersPessoaFisica() {
			return [
				{ text: '', value: 'actions', class: 'blue darken-4 white--text' },
				{
					text: 'ID Meta',
					value: 'id_meta',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Data Meta',
					value: 'dt_execucao',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Executado?',
					value: 'executado',
					class: 'blue darken-4 white--text',
					filter: (value) => {
						if (!this.filters.executado) {
							return true;
						}

						return value == this.filters.executado;
					}
				},
				{
					text: 'Data Execução',
					value: 'dt_execucao_efetivacao',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Vendedor',
					value: 'nome_vendedor',
					class: 'blue darken-4 white--text',
					filter: (value) => {
						if (!this.filters.nome_vendedor) {
							return true;
						}

						return (value.toUpperCase().indexOf(this.filters.nome_vendedor.toUpperCase()) >= 0) ? true : false;//pesquisa string dentro de outra string
					}
				},
				{
					text: 'Feedback',
					value: 'feedback',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Retornar?',
					value: 'retornar',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'CPF',
					value: 'cpf',
					class: 'blue darken-4 white--text',
					filter: (value) => {
						if (!this.filters.cpf) {
							return true;
						}

						return (value.toUpperCase().indexOf(this.filters.cpf.toUpperCase()) >= 0) ? true : false;//pesquisa string dentro de outra string
					}
				},
				{
					text: 'Nome',
					value: 'nome',
					class: 'blue darken-4 white--text',
					filter: (value) => {
						if (!this.filters.nome) {
							return true;
						}

						return (value.toUpperCase().indexOf(this.filters.nome.toUpperCase()) >= 0) ? true : false;//pesquisa string dentro de outra string
					}
				},
				{
					text: 'Telefone',
					value: 'telefone',
					class: 'blue darken-4 white--text',
					filter: (value) => {
						if (!this.filters.telefone) {
							return true;
						}

						return (value.toUpperCase().indexOf(this.filters.telefone.toUpperCase()) >= 0) ? true : false;//pesquisa string dentro de outra string
					}
				},
				{
					text: 'Data Criação',
					value: 'dt_criacao',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Usuário Criador',
					value: 'nome_usuario_criador',
					class: 'blue darken-4 white--text'
				},
			];
		},
		listagemHeadersPessoaJuridica() {
			return [
				{ text: '', value: 'actions', class: 'blue darken-4 white--text' },
				{
					text: 'ID Meta',
					value: 'id_meta',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Data Meta',
					value: 'dt_execucao',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Executado?',
					value: 'executado',
					class: 'blue darken-4 white--text',
					filter: (value) => {
						if (!this.filters.executado) {
							return true;
						}

						return value == this.filters.executado;
					}
				},
				{
					text: 'Data Execução',
					value: 'dt_execucao_efetivacao',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Vendedor',
					value: 'nome_vendedor',
					class: 'blue darken-4 white--text',
					filter: (value) => {
						if (!this.filters.nome_vendedor) {
							return true;
						}

						return (value.toUpperCase().indexOf(this.filters.nome_vendedor.toUpperCase()) >= 0) ? true : false;//pesquisa string dentro de outra string
					}
				},
				{
					text: 'Feedback',
					value: 'feedback',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Retornar?',
					value: 'retornar',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'CNPJ',
					value: 'cnpj',
					class: 'blue darken-4 white--text',
					filter: (value) => {
						if (!this.filters.cnpj) {
							return true;
						}

						return (value.toUpperCase().indexOf(this.filters.cnpj.toUpperCase()) >= 0) ? true : false;//pesquisa string dentro de outra string
					}
				},
				{
					text: 'Razão Social',
					value: 'razao_social',
					class: 'blue darken-4 white--text',
					filter: (value) => {
						if (!this.filters.razao_social) {
							return true;
						}

						return (value.toUpperCase().indexOf(this.filters.razao_social.toUpperCase()) >= 0) ? true : false;//pesquisa string dentro de outra string
					}
				},
				{
					text: 'Nome Fantasia',
					value: 'nome_fantasia',
					class: 'blue darken-4 white--text',
					filter: (value) => {
						if (!this.filters.nome_fantasia) {
							return true;
						}

						return (value.toUpperCase().indexOf(this.filters.nome_fantasia.toUpperCase()) >= 0) ? true : false;//pesquisa string dentro de outra string
					}
				},
				{
					text: 'Telefone',
					value: 'telefone',
					class: 'blue darken-4 white--text',
					filter: (value) => {
						if (!this.filters.telefone) {
							return true;
						}

						return (value.toUpperCase().indexOf(this.filters.telefone.toUpperCase()) >= 0) ? true : false;//pesquisa string dentro de outra string
					}
				},
				{
					text: 'Data Criação',
					value: 'dt_criacao',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Usuário Criador',
					value: 'nome_usuario_criador',
					class: 'blue darken-4 white--text'
				},
			];
		}
	},
	mounted() {
		this.buscaListaDefault();
		this.buscaFiltros();
	}
};
</script>
